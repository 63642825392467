import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/app/docs/node_modules/@uchiru/gatsby-theme-doctocat/src/components/layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Presentational Components`}</h2>
    <p>{` We are focusing primarily on presentational components that help standardize common design patterns. Uchiru Components components don't handle fetching and submitting data to/from APIs. If you would like to handle data in a Uchiru Component, feel free to create a wrapper around the Uchiru Component to do so.`}</p>
    <h2>{`Assume that people will break the rules, provide safe ways for them to do so`}</h2>
    <p>{`While we aim to standardize design in Uchiru Components, we also provide some flexibility with constraint-based props. We offer system props via `}<a parentName="p" {...{
        "href": "https://github.com/styled-system/styled-system"
      }}>{`styled-system`}</a>{` to allow users of the components to make small customizations, such as color and spacing, using values from the theme. Users also have the option to override the theme with a theme of their own.`}</p>
    <h2>{`Pattern Components vs Helper Components`}</h2>
    <p>{`Our components can roughly be categorized into two different component types:`}</p>
    <ul>
      <li parentName="ul">{`Pattern Components`}</li>
    </ul>
    <p>{`Pattern components help us repeat commonly used UI patterns and interactions in order to maintain our brand and provide a great user experience. Some examples of pattern components are `}<inlineCode parentName="p">{`Button`}</inlineCode>{`, `}<inlineCode parentName="p">{`Avatar`}</inlineCode>{`, or `}<inlineCode parentName="p">{`Label`}</inlineCode>{`.`}</p>
    <ul>
      <li parentName="ul">{`Helper Components`}</li>
    </ul>
    <p>{`Helper components are components that help the user achieve common CSS patterns while maintaining some control over values used. Some examples of helper components are `}<inlineCode parentName="p">{`Flex`}</inlineCode>{`, `}<inlineCode parentName="p">{`Text`}</inlineCode>{`, `}<inlineCode parentName="p">{`Grid`}</inlineCode>{`, and the `}<inlineCode parentName="p">{`Position`}</inlineCode>{` components.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      